<template>
  <div>
    <div class="d-flex justify-content-between">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1 h3">Status</label>

          <b-form-radio-group v-model="status">
            <b-form-radio
              value="2"
              class="h3"
            >
              au depot
            </b-form-radio>
          </b-form-radio-group>

        </div>
      </b-form-group>
      <div>
        <b-form-group>
          <label
            class="mr-1 h4"
            style="color:black"
          >Nombre de colis: {{ items.length }}</label>
        </b-form-group>
      </div>
    </div>
    <b-form-group
      v-if="user.role === 'admin'"
      label="Depot"
    >
      <validation-provider
        #default="{ errors }"
        name="Depot"
      >
        <v-select
          v-model="repository"
          :clearable="false"
          placeholder="Depots"
          label="name"
          :options="repositories"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group label="Référence">
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="MaximizeIcon" />
        </b-input-group-prepend>
        <b-form-input
          ref="select"
          v-model="ref"
          placeholder="Référence de colis"
          @change="checkOrder"
        />
      </b-input-group>
    </b-form-group>
    <b-card>
      <h4 class="mb-2">
        Colis
      </h4>
      <b-table
        hover
        :items="items"
        :fields="fields"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BCard,
  BTable,
  BFormRadio,
  BFormRadioGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeAuth from '@/store/store'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import reception from '@/assets/audio/reception.mp3'
import alreadyScanned from '@/assets/audio/colis-deja-scanne.mp3'
import notexist from '@/assets/audio/notexist.mp3'

export default {
  components: {
    BFormRadio,
    BFormRadioGroup,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BCard,
    BTable,
    vSelect,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: 2,
      items: [],
      item: {},
      repositories: [],
      repository: {},
      user: {},
      fields: [
        'code',
        'client',
        'gouvernorat',
        'adresse',
        'téléphone',
      ],
      ref: '',
      synth: window.speechSynthesis,
      voiceList: [],
      reception,
      alreadyScanned,
      notexist,
    }
  },
  created() {
    this.user = storeAuth.state.user
    this.getRepositories()
  },
  mounted() {
    this.$refs.select.focus()
  },
  methods: {
    async getRepositories() {
      const { data } = await axios.get('/api/repositories/')
      this.repositories = data
    },
    exists(id) {
      return this.items.some(el => el.code === (id))
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    withTitle(text) {
      this.$swal({
        title: 'Alerte',
        icon: 'warning',
        text,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(() => {
        this.ref = ''
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    async updateStatus() {
      try {
        await axios.post('/api/orders/change-status-deposit/', {
          order: this.item,
        })
        this.status = 2
        this.showToast('success', 'top-center', 'Status modifié avec succés')
        this.playAudio(reception)
      } catch (error) {
        this.showToast('danger', 'top-center', error.toString())
      }
    },
    async playAudio(payload) {
      const voice = new Audio(payload)
      voice.addEventListener('canplaythrough', () => {
        voice.play()
      })
    },
    async checkOrder() {
      let repository = null
      if (storeAuth.state.user.role === 'admin') {
        repository = this.repository.id
      } else {
        repository = storeAuth.state.user.repository
      }
      const result = this.items.find(element => element.code === Number(this.ref))
      if (result) {
        this.withTitle('Colis déja scanné')
        this.playAudio(alreadyScanned)
      } else {
        try {
          const response = await axios
            .get('/api/orders/get-status-waiting-picked/', {
              params: {
                id: this.ref,
                repository,
              },
            })
          if (response.data.length !== 0) {
            const order = response.data[0]
            this.item = {
              code: order.id,
              repository: order.repository.name,
            }
            this.items.push({
              code: order.id,
              client: order.name,
              gouvernorat: order.governorate.name,
              adresse: order.address,
              téléphone: order.phone1,
              repository: order.repository.name,
            })
            this.updateStatus()
            this.ref = ''
          } else {
            this.withTitle('Colis non exist')
            this.playAudio(notexist)
          }
        } catch (error) {
          // this.withTitle('Colis non exist')
        }
      }
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
</style>
